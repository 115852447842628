import React from 'react';
import { AvatarProps } from '@business/interfaces/avatar';

export const Avatar: React.FC<AvatarProps> = ({
  src,
  alt,
  size = 'h-10 w-10',
  rounded = true,
  className = '',
  imgClassName = '',
  presence = false,
  placeholder = ''
}) => {
  const initials = alt
    ? alt
        .split(' ')
        .map(word => word[0])
        .join('')
    : '';

  const avatarClasses = `${size} ${
    rounded ? 'rounded-full' : 'rounded-none'
  } ${className}`;

  return (
    <div className={avatarClasses}>
      {src ? (
        <img
          src={src}
          alt={alt ?? 'image'}
          width={128}
          height={128}
          className={`object-cover ${rounded ? 'rounded-full' : ''} ${
            imgClassName ?? ''
          }`}
        />
      ) : (
        <div
          className={`w-full h-full ${
            rounded ? 'rounded-full' : ''
          } bg-gray-300 flex justify-center items-center text-gray-600 font-medium uppercase`}>
          {initials || placeholder}
        </div>
      )}
      {presence && (
        <div
          className={`absolute bottom-0 right-0 w-3 h-3 border-2 border-white ${
            rounded ? 'rounded-full' : ''
          } bg-green-500`}></div>
      )}
    </div>
  );
};
