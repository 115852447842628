import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { getCognitoToken } from '@utils/helpers/lib/token';
import { store } from './store';
import { setToken } from './sessionStateSlice';
import { dotenv } from '@utils/constants/env';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: dotenv.API_BASE_URL,
  prepareHeaders: async headers => {
    const storeToken = store?.getState()?.sessionStateSlice?.token;
    const sessionToken = sessionStorage.getItem('token');
    const token = storeToken ? storeToken : sessionToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});
const baseQueryWithOutToken = fetchBaseQuery({
  baseUrl: dotenv.API_BASE_URL,
  prepareHeaders: async headers => {
    return headers;
  }
});

const baseQueryNotification = fetchBaseQuery({
  baseUrl: dotenv.NOTIFICATION_BASE_URL,
  prepareHeaders: headers => {
    const token: string = store?.getState()?.sessionStateSlice?.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions: any) => {
  const storeToken = store?.getState()?.sessionStateSlice?.token;
  const sessionToken = sessionStorage.getItem('token');
  const token = storeToken ? storeToken : sessionToken;

  const unauthorizedRoute = ['/user/callback/', '/user/notifiedfrom/'];
  let result: any;
  if (typeof args !== 'string') {
    const isUnauthorized = unauthorizedRoute.includes(args?.url);
    if (!isUnauthorized && token) {
      await mutex.waitForUnlock();
      if (extraOptions?.notification) {
        result = await baseQueryNotification(args, api, extraOptions);
      } else {
        result = await baseQuery(args, api, extraOptions);
      }

      if (result.error?.status === 401) {
        const cognitoToken = await getCognitoToken();
        const token = cognitoToken ? cognitoToken?.idToken : '';

        if (storeToken) {
          store.dispatch(
            setToken({
              token
            })
          );
        } else {
          sessionStorage.setItem('token', token);
        }
        if (extraOptions?.notification) {
          result = await baseQueryNotification(args, api, extraOptions);
        } else {
          result = await baseQuery(args, api, extraOptions);
        }
      }
      if (typeof result.error != 'undefined') {
        if (result.error && result.error.status === 400) {
          api.dispatch({ type: 'INCREMENT' });
        } else if (
          result.error &&
          result.error.status === 500 &&
          (result.error?.data as Error).message === 'jwt expired'
        ) {
          /* Call logoutUser */
        }
      }
    } else if (isUnauthorized) {
      result = await baseQueryWithOutToken(args, api, extraOptions);
    } else {
      return null;
    }
  }
  return result;
};
