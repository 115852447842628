import loginBackground from './login-background.webp';
import MapMarker from './map-marker.webp';
import ProfileImage from './profile-image.webp';
import CoverImage from './cover-image.webp';
import FrameBackground from './frame-bg.webp';
import MyArea from './my-area.webp';
import Loader from './loader.gif';
import SetStates from './set-states.webp';

export const Images = {
  loginBackground,
  MapMarker,
  ProfileImage,
  CoverImage,
  FrameBackground,
  MyArea,
  Loader,
  SetStates
};
