import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from 'aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { SVGAssets } from '@business/assets/vectors';
import { routePaths } from '@utils/constants/routePaths';
import { setToken } from '@ports/common/sessionStateSlice';
import { setIsManualLoading } from '@ports/common/manualLoadingStateSlice';
import { useNavigate } from 'react-router-dom';
import { SidebarProps } from '@business/interfaces/sidebar';
import { Button } from '../Button';
import { getUserData } from '@ports/common/userStateSlice';

export const Sidebar: React.FC<SidebarProps> = ({ isAdmin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserData);
  const usePermissions = useMemo(() => {
    return (
      userDetails?.results?.permissions?.map(
        permission => permission?.codename
      ) || []
    );
  }, [userDetails]);
  const menuItems = [
    {
      label: t('sidebar.user'),
      href: '#',
      permission: 'view_user_management'
    },
    {
      label: t('sidebar.local'),
      href: '#',
      permission: 'view_manage_local_area'
    },
    {
      label: t('sidebar.superRegions'),
      href: '#',
      permission: 'view_manage_super_region'
    },
    {
      label: t('sidebar.states'),
      href: routePaths.StateList,
      permission: 'view_manage_states'
    },
    {
      label: t('sidebar.country'),
      href: routePaths.CountryList,
      permission: 'view_manage_country'
    },
    {
      label: t('sidebar.zip'),
      href: '#',
      permission: 'view_zip_code_management'
    },
    {
      label: t('sidebar.profile'),
      href: routePaths.Profile,
      permission: 'view_profile'
    },
    {
      label: t('sidebar.preference'),
      href: routePaths.Preferences,
      permission: 'view_my_preference_and_area'
    },
    {
      label: t('sidebar.schedule'),
      href: routePaths.Schedules,
      permission: 'view_schedule'
    },
    {
      label: t('sidebar.training'),
      href: routePaths.Training,
      permission: 'view_my_training'
    },
    { label: t('sidebar.signOut'), href: '#', permission: 'signOut' }
  ];

  const [activeItem, setActiveItem] = useState(menuItems[0].label);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentPath = window.location.pathname;

    const foundItem = menuItems.find(item => {
      if (item.href === '#' && currentPath === '/') {
        return true;
      }
      return currentPath.includes(item.href);
    });

    if (foundItem) {
      setActiveItem(foundItem.label);
    } else {
      setActiveItem(menuItems[0].label);
    }
  }, [menuItems]);

  const handleItemClick = async (label: string) => {
    setActiveItem(label);
    if (label === t('sidebar.signOut')) {
      dispatch(
        setIsManualLoading({
          isLoading: true
        })
      );
      await signOut();
      dispatch(setToken({ token: null }));
      localStorage.clear();
      sessionStorage.clear();
      navigate(routePaths.SignIn);
      dispatch(
        setIsManualLoading({
          isLoading: false
        })
      );
    }
  };

  function sideNavMenu() {
    const element2 = document.getElementById('sideNav');
    const element3 = document.getElementById('sideNavMenu');
    element2.classList.toggle('sideNav-toggle');
    element3.classList.toggle('rotate-img');
    setIsVisible(!isVisible);
  }

  return (
    <div className="w-full lg:w-2/12 mb-3 lg:mb-0">
      <div className="bg-white rounded-lg w-full h-full lg:w-11/12">
        <div className="flex justify-between items-center border-b-primary">
          <h4 className="secondary-font color-secondary-hover p-4">
            {isAdmin ? t('sidebar.admin') : t('sidebar.title')}
          </h4>
          <SVGAssets.Expand
            className="w-5 mr-5 lg:hidden cursor-pointer"
            id="sideNavMenu"
            data-testid="sideNavMenu"
            onClick={sideNavMenu}
          />
        </div>
        <ul
          className={`nav hidden lg:block ${isVisible ? 'sideNav-toggle' : ''}`}
          id="sideNav"
          data-testid="sideNav">
          {menuItems.map(item => (
            <>
              {item.permission &&
                (usePermissions.includes(item.permission) ||
                  item?.permission === 'signOut') && (
                  <li
                    key={item.label}
                    className={`${activeItem === item.label ? 'active font-bold' : 'font-bold text-[var(--na-lite-gray4)]'}`}>
                    <Button
                      btnStyle="link"
                      className={`w-full text-left `}
                      href={item.href}
                      onClick={() => handleItemClick(item.label)}>
                      {item.label}
                    </Button>
                  </li>
                )}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};
