import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getToken } from '@ports/common/sessionStateSlice';

export const Footer = () => {
  const { t } = useTranslation();
  const token: string | null = useSelector(getToken);
  return (
    <footer
      className={`copyright-row fixed text-gray ${
        !token
          ? 'bottom-5 md:left-5 text-center md:text-white pb-2'
          : 'bottom-2 right-20 md:right-24 text-right'
      }  md:pb-0 md:text-left mt-2 text-xs`}>
      <span className="cursor-pointer">{t('termsOfUse')}</span>{' '}
      {!token ? (
        <>
          {' '}
          | <span className="cursor-pointer">{t('privacyPolicy')}</span> |{' '}
          {t('copyRight')} © 2023
        </>
      ) : (
        '|'
      )}
    </footer>
  );
};
