import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { StateProps } from '@business/interfaces/state';

const initialState: StateProps = {
  id: ''
};

export const stateStateSlice = createSlice({
  name: 'stateSlice',
  initialState,
  reducers: {
    setStateId: (state, action) => {
      state.id = action.payload.id;
    }
  }
});

export const { setStateId } = stateStateSlice.actions;
export const getStateId = (state: RootState) => state.stateSlice;
