import { ReactComponent as AlertCheck } from './alert-check.svg';
import { ReactComponent as AlertError } from './alert-error.svg';
import { ReactComponent as AlertInfo } from './alert-info.svg';
import { ReactComponent as AlertWarning } from './alert-warning.svg';
import { ReactComponent as CloseBtn } from './close-btn.svg';
import { ReactComponent as TrashIcon } from './trash-icon.svg';
import { ReactComponent as ThreeDots } from './threeDots.svg';
import { ReactComponent as VisibilityOff } from './visibility-off.svg';
import { ReactComponent as LocationIcon } from './location.svg';
import { ReactComponent as FamilyIcon } from './family-icon.svg';
import { ReactComponent as VolunteerIcon } from './volunteer-icon.svg';
import { ReactComponent as UserIcon } from './user-icon.svg';
import { ReactComponent as CloseButtonRounded } from './close-button-rounded.svg';
import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as ProfileSidebar } from './profile-sidebar-icon.svg';
import { ReactComponent as PadLock } from './pad-lock.svg';
import { ReactComponent as GoogleIcon } from './google-icon.svg';
import { ReactComponent as FacebookIcon } from './facebook-icon.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as HomeMenu } from './menu-home-icon.svg';
import { ReactComponent as MatchMenu } from './menu-match-icon.svg';
import { ReactComponent as EventsMenu } from './menu-events-icon.svg';
import { ReactComponent as ResourcesMenu } from './menu-resources-icon.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as ProfileEdit } from './profile-edit.svg';
import { ReactComponent as CoverImageEdit } from './cover-image-edit.svg';
import { ReactComponent as InfoIcon } from './info-icon.svg';
import { ReactComponent as SearchIcon } from './search-icon.svg';
import { ReactComponent as SortIcon } from './sort-icon.svg';
import { ReactComponent as EditIcon } from './edit-icon.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as DownArrow } from './down-arrow.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LasagnaLogo } from './lasagna-logo.svg';
import { ReactComponent as EmptyRecentMatches } from './recent-matches-empty.svg';
import { ReactComponent as ScheduleEmpty } from './schedule-empty.svg';
import { ReactComponent as PreviousArrow } from './previous-arrow.svg';
import { ReactComponent as MatchesIcon } from './matches-icon.svg';
import { ReactComponent as PlusIcon } from './plus-icon.svg';
import { ReactComponent as TelephoneIcon } from './telephone-icon.svg';
import { ReactComponent as EmailIcon } from './email-icon.svg';
import { ReactComponent as ExportIcon } from './export-icon.svg';
import { ReactComponent as DeliveryIcon } from './delivery-icon.svg';
import { ReactComponent as RequestIcon } from './request-icon.svg';
import { ReactComponent as RepeatedRequestIcon } from './repeated-requests.svg';
import { ReactComponent as VolunteerInsightIcon } from './volunteers.svg';
import { ReactComponent as LeadersIcon } from './leaders-icon.svg';
import { ReactComponent as PasswordIcon } from './password-icon.svg';
import { ReactComponent as EditPen } from './edit-pen.svg';
import { ReactComponent as TikMark } from './tik-mark.svg';
import { ReactComponent as ZipIcon } from './zip-icon.svg';
import { ReactComponent as ExportCSV } from './export-csv.svg';
import { ReactComponent as LocationInputIcon } from './location-input.svg';
import { ReactComponent as UploadIcon } from './upload-icon.svg';
import { ReactComponent as CircleDownArrow } from './circle-down-arrow.svg';
import { ReactComponent as EyeIcon } from './eye-icon.svg';
import { ReactComponent as GalleryAdd } from './gallery-add.svg';
import { ReactComponent as ProfileBorderIcon } from './profile-border-icon.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Regions } from './regions.svg';
import { ReactComponent as Manage } from './manage.svg';
import { ReactComponent as HeaderUser } from './header-user.svg';

export const SVGAssets = {
  AlertCheck,
  AlertError,
  AlertInfo,
  AlertWarning,
  CloseBtn,
  TrashIcon,
  ThreeDots,
  VisibilityOff,
  LocationIcon,
  FamilyIcon,
  VolunteerIcon,
  UserIcon,
  CloseButtonRounded,
  LeftArrow,
  ProfileSidebar,
  EditIcon,
  PadLock,
  GoogleIcon,
  FacebookIcon,
  Menu,
  HomeMenu,
  MatchMenu,
  EventsMenu,
  ResourcesMenu,
  Expand,
  InfoIcon,
  SearchIcon,
  SortIcon,
  ProfileEdit,
  CoverImageEdit,
  Logo,
  LasagnaLogo,
  EmptyRecentMatches,
  ScheduleEmpty,
  PreviousArrow,
  MatchesIcon,
  PlusIcon,
  TelephoneIcon,
  EmailIcon,
  ExportIcon,
  DeliveryIcon,
  RequestIcon,
  RepeatedRequestIcon,
  VolunteerInsightIcon,
  LeadersIcon,
  Question,
  DownArrow,
  PasswordIcon,
  EditPen,
  TikMark,
  ZipIcon,
  ExportCSV,
  LocationInputIcon,
  UploadIcon,
  CircleDownArrow,
  EyeIcon,
  GalleryAdd,
  ProfileBorderIcon,
  Settings,
  Regions,
  Manage,
  HeaderUser
};
