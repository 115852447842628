import React from 'react';
import { useTranslation } from 'react-i18next';
import { SVGAssets } from '@business/assets/vectors';
import ZipCodeItem from './ZipCodeItem';

interface AssignedZipCodeCardProps {
  selectedZipCodes?: string[];
  onRemoveZipCode?: (zipCode: string) => void;
}

export const AssignedZipCodeCard: React.FC<AssignedZipCodeCardProps> = ({
  selectedZipCodes,
  onRemoveZipCode
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center p-2">
        <SVGAssets.ZipIcon
          className="w-6 h-6 text-white rounded-full flex items-center justify-center border-primary-color bg-[var(--na-primary-color)] p-1"
          data-testid="location-icon"
        />
        <h4 className="text-[var(--na-primary-color-active)] font-semibold ml-3">
          {t('area.zip-codes')}
        </h4>
      </div>
      <hr className="w-full border-[var(--na-primary-color-hover)] border-t mt-1" />
      <div className="p-6 custom-scrollbar common-scrollbar overflow-y-auto md:!h-[200px]">
        <div className="flex flex-wrap gap-2">
          {selectedZipCodes?.map(zip => (
            <ZipCodeItem
              key={zip}
              zipCodes={zip}
              onClose={() => onRemoveZipCode(zip)}
            />
          ))}
        </div>
      </div>
    </>
  );
};
