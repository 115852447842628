import React, { FC, forwardRef } from 'react';
import { SelectProps } from '@business/interfaces/select/selectProps';

export const Select: FC<SelectProps> = forwardRef<
  HTMLSelectElement,
  SelectProps
>(
  (
    {
      inputWrapperClassName,
      label,
      labelClassName,
      fieldSize,
      color,
      error,
      startDecorator,
      endDecorator,
      options,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div
          data-testid="input-select-wrapper"
          className={`${inputWrapperClassName} ${error ? 'error' : color}`}>
          {startDecorator}
          <div className="w-full">
            <select
              {...props}
              ref={ref}
              id={props.name}
              value={props.value}
              disabled={props.disabled}
              data-testid={`select-input-${props.name}`}>
              {options?.map((option, index) => (
                <option
                  data-testid={option.value}
                  key={option.value}
                  value={option.value}
                //className="gray-input-label"
                >
                  {option.label}
                </option>
              ))}
            </select>
            {label && (
              <label
                htmlFor={props.name}
                data-testid="input-label"
                className={labelClassName}>
                {label}
              </label>
            )}
          </div>
          {endDecorator}
        </div>
        {error && <div className="error-msg text-xs pt-1">{error}</div>}
      </>
    );
  }
);

Select.displayName = 'Select';
Select.defaultProps = {
  color: 'success',
  inputWrapperClassName: '',
  className: 'select items-center justify-between',
  labelClassName:
    'pl-2 absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
};
