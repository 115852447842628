export const localItems = [
  {
    id: 1,
    uuid: '1',
    name: 'John Doe',
    imageUrl: 'https://randomuser.me/api/portraits/men/1.jpg'
  },
  {
    id: 2,
    uuid: '2',
    name: 'Jane Smith',
    imageUrl: 'https://randomuser.me/api/portraits/men/2.jpg'
  },
  {
    id: 3,
    uuid: '3',
    name: 'Mike Johnson',
    imageUrl: 'https://randomuser.me/api/portraits/men/3.jpg'
  },
  {
    id: 4,
    uuid: '4',
    name: 'Mike Smith',
    imageUrl: 'https://randomuser.me/api/portraits/men/4.jpg'
  },
  {
    id: 5,
    uuid: '5',
    name: 'Smith Johnson',
    imageUrl: 'https://randomuser.me/api/portraits/men/5.jpg'
  },
  {
    id: 6,
    uuid: '6',
    name: 'Mike Johnson',
    imageUrl: 'https://randomuser.me/api/portraits/men/6.jpg'
  },
  {
    id: 7,
    uuid: '7',
    name: 'Johnson Smith',
    imageUrl: 'https://randomuser.me/api/portraits/men/7.jpg'
  },
  {
    id: 8,
    uuid: '8',
    name: 'Olivia Martinez',
    imageUrl: 'https://randomuser.me/api/portraits/men/8.jpg'
  },
  {
    id: 9,
    uuid: '9',
    name: 'Liam Anderson',
    imageUrl: 'https://randomuser.me/api/portraits/men/9.jpg'
  },
  {
    id: 10,
    uuid: '10',
    name: 'Isabella Thomas',
    imageUrl: 'https://randomuser.me/api/portraits/men/10.jpg'
  }
];

export const zipCodes = [
  {
    zipCode: '99901',
    location: 'Holtsville, New York, USA',
    districtId: '24, Long Island'
  },
  {
    zipCode: '90210',
    location: 'Beverly Hills, California, USA',
    districtId: '64, Short Island'
  },
  {
    zipCode: '30301',
    location: 'Atlanta, Georgia, USA',
    districtId: '28, Long Georgia'
  },
  {
    zipCode: '60614',
    location: 'Chicago, Illinois, USA',
    districtId: '14, Illinois Island'
  },
  { zipCode: '94103', location: 'San Francisco, California, USA' },
  { zipCode: '10001', location: 'New York, New York, USA' },
  { zipCode: '33101', location: 'Miami, Florida, USA' },
  { zipCode: '73301', location: 'Austin, Texas, USA' },
  { zipCode: '98101', location: 'Seattle, Washington, USA' },
  { zipCode: '19103', location: 'Philadelphia, Pennsylvania, USA' },
  { zipCode: '02115', location: 'Boston, Massachusetts, USA' },
  { zipCode: '85001', location: 'Phoenix, Arizona, USA' },
  { zipCode: '75201', location: 'Dallas, Texas, USA' },
  { zipCode: '48201', location: 'Detroit, Michigan, USA' },
  { zipCode: '15201', location: 'Pittsburgh, Pennsylvania, USA' },
  { zipCode: '64101', location: 'Kansas City, Missouri, USA' }
];

export const localAreaData = [
  {
    id: 1,
    cityName: 'Puerto Rico',
    cityId: 42,
    primaryCity: 'San Juan',
    state: 'PR',
    country: 'USA',
    countryCode: 'USA',
    countryName: 'Puerto Rico',
    timezone: 'America/New_York',
    isMatched: 'Yes',
    isReal: 'Yes',
    localLeaders: [
      {
        id: 1,
        name: 'John Doe',
        imageUrl: 'https://randomuser.me/api/portraits/men/1.jpg'
      },
      {
        id: 2,
        name: 'Jane Smith',
        imageUrl: 'https://randomuser.me/api/portraits/men/2.jpg'
      },
      {
        id: 3,
        name: 'Mike Johnson',
        imageUrl: 'https://randomuser.me/api/portraits/men/3.jpg'
      }
    ],
    zipCodes: [{ zipCode: '10001' }, { zipCode: '10002' }, { zipCode: '10003' }]
  },
  {
    id: 2,
    cityName: 'New York',
    cityId: 43,
    primaryCity: 'New York City',
    state: 'NY',
    country: 'USA',
    countryCode: 'New_York',
    countryName: 'New York',
    timezone: 'America/New_York',
    isMatched: 'No',
    isReal: 'No',
    localLeaders: [
      {
        id: 13,
        name: 'Alice Brown',
        imageUrl: 'https://randomuser.me/api/portraits/men/4.jpg'
      },
      {
        id: 14,
        name: 'Bob White',
        imageUrl: 'https://randomuser.me/api/portraits/men/5.jpg'
      }
    ],
    zipCodes: [{ zipCode: '20001' }, { zipCode: '20002' }]
  }
];

export const zip: any = {
  count: 1,
  results: [
    {
      id: 5,
      state: 'yhy',
      district: 'Joneston',
      zip: '30301',
      zip_type: 'focus',
      primary_city: 'Arnoldbury',
      county: null,
      timezone: 'Timezone object (5)',
      area_codes: null,
      latitude: '-12.31',
      longitude: '6.53',
      decommissioned: 1,
      super_region_name: 'Ok nearly point.',
      matched_with_name: 'Morgan Simpson',
      timezone_offset: null
    }
  ]
};
