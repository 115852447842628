import { zipCodeApi as api } from './zipCodeApi';
export const addTagTypes = ['region'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      regionZipcodesList: build.query<
        RegionZipcodesListApiResponse,
        RegionZipcodesListApiArg
      >({
        query: queryArg => ({
          url: `/region/zipcodes/`,
          params: {
            country__name: queryArg.countryName,
            state__name: queryArg.stateName,
            zip: queryArg.zip,
            district__name: queryArg.districtName,
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type RegionZipcodesListApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: ZipCodesRead[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type RegionZipcodesListApiArg = {
  /** country__name */
  countryName?: string;
  /** state__name */
  stateName?: string;
  /** zip */
  zip?: string;
  /** district__name */
  districtName?: string;
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ZipCodes = {
  state?: string;
  region?: string;
  district?: string;
  country?: string;
  zip: string;
  zip_type?: string | null;
  primary_city?: string | null;
  county?: string | null;
  timezone?: string | null;
  area_codes?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  decommissioned?: number | null;
  super_region_name?: string | null;
  matched_with_name?: string | null;
  timezone_offset?: string | null;
};
export type ZipCodesRead = {
  id?: number;
  state?: string;
  region?: string;
  district?: string;
  country?: string;
  uuid?: string;
  zip: string;
  zip_type?: string | null;
  primary_city?: string | null;
  county?: string | null;
  timezone?: string | null;
  area_codes?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  decommissioned?: number | null;
  super_region_name?: string | null;
  matched_with_name?: string | null;
  timezone_offset?: string | null;
};
export const { useRegionZipcodesListQuery } = injectedRtkApi;
