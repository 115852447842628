import { Link } from 'react-router-dom';
import React from 'react';

export type Path = {
  label: string;
  href?: string;
};

export interface BreadcrumbProps {
  paths: Path[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ paths }) => {
  const pathLength: number = paths?.length ?? 0;
  return (
    <div className="breadcrumbs pb-0" data-testid="bread-Crumb-tag">
      {paths.map((path, index) => (
        <span key={`breadcrumb-${path?.href}`} data-testid="breadcrumb-item">
          {index > 0 && <span className="separator"> {'>'} </span>}
          {path.href ? (
            <Link
              to={path.href}
              className="hover-link text-[var(--na-lite-gray)] font-bold cursor-pointer capitalize">
              {path.label}
            </Link>
          ) : (
            <span
              className={`font-bold capitalize ${
                pathLength === 1 || (pathLength > 1 && index == 0)
                  ? 'text-[var(--na-lite-gray)]'
                  : 'text-black'
              }`}>
              {path.label}
            </span>
          )}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
