import { Images } from '@business/assets/images';
import { Leader } from './LocalLeaderCard';
import { SVGAssets } from '@business/assets/vectors';

interface LeaderCardItemProps {
  leader?: Leader;
  onRemove?: (id: string) => void;
  isCountryDetail?: boolean;
}

export const LeaderCardItem: React.FC<LeaderCardItemProps> = ({
  leader,
  onRemove,
  isCountryDetail
}) => {
  return (
    <div className="relative w-full md:w-1/5 p-3 pr-2">
      {!isCountryDetail ? (
        <>
          <SVGAssets.CloseButtonRounded
            className="w-4 h-4 volunteer-close absolute top-2 right-1 cursor-pointer"
            data-testid="close-button"
            onClick={() => onRemove(leader?.uuid)}
          />
          <div className="bg-white card-shadow rounded-lg flex flex-col items-center p-4">
            <img
              src={leader?.imageUrl ?? Images.ProfileImage}
              alt={leader?.name}
              height={100}
              width={100}
              className="w-11 h-11 rounded-full border border-gray-300 mb-2"
            />
            <p className="text-center text-xs color-primary overflow-hidden text-ellipsis whitespace-nowrap">
              {leader?.first_name} {leader?.last_name}
            </p>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <img
            src={leader?.imageUrl ?? Images.ProfileImage}
            alt={leader?.name}
            height={100}
            width={100}
            className="w-11 h-11 rounded-full border border-gray-300 mb-2"
          />
          <p
            className={`text-center ${isCountryDetail ? 'text-[var(--na-secondary-color-hover)] text-sm' : 'color-primary text-xs'} overflow-hidden text-ellipsis whitespace-nowrap`}>
            {leader?.first_name} {leader?.last_name}
          </p>
        </div>
      )}
    </div>
  );
};
